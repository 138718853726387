import styled from 'styled-components'

const container = styled.main`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 816px) {
    justify-content: center;
  }

  header {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;

    h1 {
      display: inline-block;
      font-family: 'Dancing Script', cursive;
      letter-spacing: 0.1rem;
      font-size: 2.5rem;
      font-weight: 700;
      color: #152458;
      border-radius: 0.25rem;
      border-bottom: 0.25rem solid #152458;
    }
  }
`

const imageItem = styled.div`
  padding: 0 1rem 2rem 1rem;

  h2 {
    display: inline-block;
    text-align: center;
    font-family: 'Dancing Script', cursive;
    letter-spacing: 0.1rem;
    font-size: 2rem;
    font-weight: 700;
    color: #152458;
    border-bottom: 0.15rem solid #152458;
    padding: 0.25rem 0.4rem;
    transition: background 0.4s;

    &:hover {
      background: #152458;
      color: #fff;
    }
  }

  a {
    text-decoration: none;
  }
`

export default { container, imageItem }
