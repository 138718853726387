import styled from 'styled-components'

const Contact = styled.section`
  margin: 5rem 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
  }

  h1 {
    font-family: 'Dancing Script', cursive;
    letter-spacing: 0.1rem;
    font-size: 2.5rem;
    font-weight: 700;
    color: #152458;
    border-bottom: 0.25rem solid #152458;
    border-radius: 0.25rem;
    display: inline-block;
    margin-bottom: 3rem;
  }
`

const ContactWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;

  address {
    color: #152458;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
  }
`

const Business = styled.aside`
  width: 48%;
  height: 15rem;
  margin: 2rem 0.5rem;

  h2 {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    letter-spacing: 0.1rem;
    font-size: 2rem;
    font-weight: 700;
    color: #152458;
  }
`

const BusinessWrapper = styled.div`
  background-color: #1f54a2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  address {
    align-self: center;
    height: 10rem;

    h6 {
      margin-top: 2rem;
      font-size: 1.5rem;
      color: #eee;
    }

    p {
      color: #fff;
      font-size: 1.25rem;
      padding: 0.25rem;
      font-weight: 500;

      & .icon-phone {
        margin-right: 0.35rem;
      }

      span {
        font-size: 1rem;
      }

      a {
        text-decoration: none;
        color: #fff;
        transition: color 0.2s;

        &:hover {
          color: #34af23;
        }

        & .icon-mobile {
          margin-right: 0.35rem;
          font-size: 1.25rem;
          font-weight: bold;
        }
      }
    }
  }
`

const Social = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  a {
    text-decoration: none;
    font-size: 2.5rem;
    margin: 0 0.5rem;
    font-weight: 500;
    color: #fff;
  }
`

export default { Contact, ContactWrapper, Business, BusinessWrapper, Social }
