import styled from 'styled-components'
import waveDeskNormal from '../../assets/wave-grc-1.svg'
import waveDeskLarge from '../../assets/wave-grc-3.svg'

const ContactWrapper = styled.footer`
  width: 100%;
  max-width: 2560px;
  height: 42rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1920px) {
    height: 19.5rem;
  }
  @media (max-width: 1720px) {
    height: 17.5rem;
  }
  @media (max-width: 1520px) {
    height: 15.5rem;
  }
  @media (max-width: 1320px) {
    height: 12.5rem;
  }
  @media (max-width: 1120px) {
    height: 11.5rem;
  }
  @media (max-width: 800px) {
    height: 10rem;
  }
  @media (max-width: 600px) {
    height: 15.5rem;
  }

  div {
    height: 100%;
    width: 100%;
    display: flex;
    background-image: url(${waveDeskNormal});
    background-repeat: no-repeat;
    transform: scaleY(-1);
    justify-content: center;

    @media (max-width: 600px) {
      background-image: url(${waveDeskLarge});
    }
  }

  img {
    height: 100%;
    width: 100%;
  }

  p {
    text-align: center;
    align-self: center;
    transform: scaleY(-1);
    color: #fff;
    margin-top: -5rem;

    @media (max-width: 800px) {
      margin-top: -4rem;
    }

    @media (max-width: 600px) {
      margin-top: -7rem;
    }
  }
`

export default {
  ContactWrapper
}
