import styled from 'styled-components'

const container = styled.main`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 816px) {
    justify-content: center;
  }

  header {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;

    h1 {
      display: inline-block;
      font-family: 'Dancing Script', cursive;
      letter-spacing: 0.1rem;
      font-size: 2.5rem;
      font-weight: 700;
      color: #152458;
      border-bottom: 0.25rem solid #152458;
      border-radius: 0.25rem;
    }
  }
`
const imageItem = styled.div`
  padding: 0 1rem 2rem 1rem;
  width: 33.33%;
  display: flex;
  flex-direction: column;

  &:last-child {
    align-self: center;
    justify-self: center;
    margin: auto;
  }

  @media (max-width: 1216px) {
    width: 50%;
  }

  @media (max-width: 816px) {
    width: 35rem;
  }

  @media (max-width: 616px) {
    width: 40rem;
  }

  h2 {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    letter-spacing: 0.1rem;
    font-size: 2rem;
    font-weight: 700;
    color: #152458;
  }
`

const imageWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 0.75rem;
  box-shadow: 8px 8px 7px 0px rgba(21, 36, 88, 0.7);

  &:hover {
    & > div {
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 3;
    }

    img {
      transform: scale(1.25);
    }
  }

  img {
    display: flex;
    width: 100%;
    transition: transform 0.4s;
  }
`

export default { container, imageItem, imageWrapper }
