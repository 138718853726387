import React from 'react'
import bacalhauDesf from '../../assets/bacalhau-salgado.jpg'
import camaraoRosa from '../../assets/camarao-rosa.jpg'
import fileDeSalmao from '../../assets/file-de-salmao.jpg'
import lomboPintado from '../../assets/lombo-de-pintado.jpg'
import lulaEmAnel from '../../assets/lula-em-aneis.jpg'
import postasDourada from '../../assets/postas-de-dourada.jpg'
import tambaquiInteiro from '../../assets/tambaqui-inteiro.jpg'
import S from './index'

function Main () {
  return (
    <S.container>
      <header>
        <h1>Linha de Produtos</h1>
      </header>

      <S.imageItem>
        <h2>Bacalhau</h2>
        <S.imageWrapper>
          <div></div>
          <img
            src={bacalhauDesf}
            alt='Bacalhau Gadus macrocephalus salgado e desfiado'
          />
        </S.imageWrapper>
      </S.imageItem>

      <S.imageItem>
        <h2>Camarões</h2>
        <S.imageWrapper>
          <div></div>
          <img
            src={camaraoRosa}
            alt='Camarão Rosa limpo e eviscerado com cauda'
          />
        </S.imageWrapper>
      </S.imageItem>

      <S.imageItem>
        <h2>Filés de Peixe</h2>
        <S.imageWrapper>
          <div></div>
          <img src={fileDeSalmao} alt='Filé de Salmão Premium com pele' />
        </S.imageWrapper>
      </S.imageItem>

      <S.imageItem>
        <h2>Frutos do Mar</h2>
        <S.imageWrapper>
          <div></div>
          <img src={lulaEmAnel} alt='Lula em anéis' />
        </S.imageWrapper>
      </S.imageItem>

      <S.imageItem>
        <h2>Peixes Inteiros</h2>
        <S.imageWrapper>
          <div></div>
          <img src={tambaquiInteiro} alt='Tambaqui inteiro e eviscerado' />
        </S.imageWrapper>
      </S.imageItem>

      <S.imageItem>
        <h2>Lombos de Peixe</h2>
        <S.imageWrapper>
          <div></div>
          <img src={lomboPintado} alt='Lombo de pintado sem pele' />
        </S.imageWrapper>
      </S.imageItem>

      <S.imageItem>
        <h2>Postas de Peixe</h2>
        <S.imageWrapper>
          <div></div>
          <img src={postasDourada} alt='Postas de Dourada sem pele' />
        </S.imageWrapper>
      </S.imageItem>
    </S.container>
  )
}

export default Main
