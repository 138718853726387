import styled from 'styled-components'

const Spinner = styled.div`
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;

  & .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  & .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #152458;
    border-radius: 100%;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  & .sk-circle2 {
    transform: rotate(30deg);
  }
  & .sk-circle3 {
    transform: rotate(60deg);
  }
  & .sk-circle4 {
    transform: rotate(90deg);
  }
  & .sk-circle5 {
    transform: rotate(120deg);
  }
  & .sk-circle6 {
    transform: rotate(150deg);
  }
  & .sk-circle7 {
    transform: rotate(180deg);
  }
  & .sk-circle8 {
    transform: rotate(210deg);
  }
  & .sk-circle9 {
    transform: rotate(240deg);
  }
  & .sk-circle10 {
    transform: rotate(270deg);
  }
  & .sk-circle11 {
    transform: rotate(300deg);
  }
  & .sk-circle12 {
    transform: rotate(330deg);
  }
  & .sk-circle2:before {
    animation-delay: -1.1s;
  }
  & .sk-circle3:before {
    animation-delay: -1s;
  }
  & .sk-circle4:before {
    animation-delay: -0.9s;
  }
  & .sk-circle5:before {
    animation-delay: -0.8s;
  }
  & .sk-circle6:before {
    animation-delay: -0.7s;
  }
  & .sk-circle7:before {
    animation-delay: -0.6s;
  }
  & .sk-circle8:before {
    animation-delay: -0.5s;
  }
  & .sk-circle9:before {
    animation-delay: -0.4s;
  }
  & .sk-circle10:before {
    animation-delay: -0.3s;
  }
  & .sk-circle11:before {
    animation-delay: -0.2s;
  }
  & .sk-circle12:before {
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }

  @keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
`

const CategoriesWrapper = styled.main`
  display: flex;
  margin: 0 auto;
  width: 98%;
  min-height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  @media (max-width: 1060px) {
    flex-direction: column;
  }

  h2 {
    display: inline-block;
    text-align: center;
    font-family: 'Dancing Script', cursive;
    letter-spacing: 0.1rem;
    font-size: 2.5rem;
    font-weight: 700;
    color: #152458;
    border-bottom: 0.25rem solid #152458;
    border-radius: 0.25rem;
  }

  img {
    width: 42%;
    margin: 1rem;
    border-radius: 0.75rem;
    box-shadow: 8px 8px 7px 0px rgba(21, 36, 88, 0.7);

    @media (max-width: 1060px) {
      margin: 1rem auto;
      width: 80%;
    }
  }
`

const CategoryName = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
`

const BackToMenuProducts = styled.div`
  position: absolute;
  height: 3rem;
  width: 3rem;
  display: inline-block;
  left: 5.5rem;
  top: 0;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 3rem;
    line-height: 3rem;
    text-decoration: none;
    color: #152458;

    &:hover {
      opacity: 0.8;
    }
  }
`

export default { Spinner, CategoriesWrapper, CategoryName, BackToMenuProducts }
