import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import polacaDesfiada from '../../assets/bacalhaus/(000092).jpg'
import mantaBacalhau from '../../assets/bacalhaus/(000131).jpg'
import fileBacalhau from '../../assets/bacalhaus/(000148).jpg'
import bacalhauDesfiado from '../../assets/bacalhaus/(000258).jpg'
import lomboBacalhau from '../../assets/bacalhaus/(000309).jpg'
import seteBBSCabeca from '../../assets/camaroes/(000037).jpg'
import seteBBEspecial from '../../assets/camaroes/(000066).jpg'
import vannameiSCabeca from '../../assets/camaroes/(000067).jpg'
import rosaInteiro11_15 from '../../assets/camaroes/(000089).jpg'
import rosaInteiro21_30 from '../../assets/camaroes/(000091).jpg'
import rosaLimpo21_30 from '../../assets/camaroes/(000093).jpg'
import rosaLimpo50_60 from '../../assets/camaroes/(000095).jpg'
import rosaLimpo31_40 from '../../assets/camaroes/(000125).jpg'
import vannameiDescascado from '../../assets/camaroes/(000140).jpg'
import rosaLimpo16_20 from '../../assets/camaroes/(000152).jpg'
import seteBBM from '../../assets/camaroes/(000156).jpg'
import seteBBG from '../../assets/camaroes/(000157).jpg'
import fileLinguado from '../../assets/files-de-peixe/(000001).jpg'
import fileMerluzao from '../../assets/files-de-peixe/(000006).jpg'
import filePescadaEsp from '../../assets/files-de-peixe/(000010).jpg'
import fileAbrotea from '../../assets/files-de-peixe/(000015).jpg'
import filePangaPremium from '../../assets/files-de-peixe/(000022).jpg'
import fileSardinhaEsp from '../../assets/files-de-peixe/(000030).jpg'
import fileMerluza from '../../assets/files-de-peixe/(000045).jpg'
import fileAbadejo from '../../assets/files-de-peixe/(000048).jpg'
import fileSurubim from '../../assets/files-de-peixe/(000078).jpg'
import fileCambucu from '../../assets/files-de-peixe/(000083).jpg'
import fileSalmaoB from '../../assets/files-de-peixe/(000106).jpg'
import fileTilapia from '../../assets/files-de-peixe/(000127).jpg'
import fileSalmaoPremium from '../../assets/files-de-peixe/(000136).jpg'
import filePescada from '../../assets/files-de-peixe/(000296).jpg'
import fileCongroRosa from '../../assets/files-de-peixe/(010121).jpg'
import filePangaGord from '../../assets/files-de-peixe/(020033).jpg'
import kaniInak from '../../assets/frutos-do-mar/(000026).jpg'
import carneDesfiada from '../../assets/frutos-do-mar/(000050).jpg'
import siriPuro from '../../assets/frutos-do-mar/(000057).jpg'
import polvoEvisc from '../../assets/frutos-do-mar/(000060).jpg'
import tentaculosLula from '../../assets/frutos-do-mar/(000072).jpg'
import lulaTubo from '../../assets/frutos-do-mar/(000094).jpg'
import lulaAneis from '../../assets/frutos-do-mar/(000104).jpg'
import cabecaLula from '../../assets/frutos-do-mar/(000105).jpg'
import caudaLagosta from '../../assets/frutos-do-mar/(000123).jpg'
import mariscoLimpo from '../../assets/frutos-do-mar/(011940).jpg'
import mariscoMeiaConcha from '../../assets/frutos-do-mar/(030026).jpg'
import kitPaella from '../../assets/frutos-do-mar/(110008).jpg'
import sardinhaEvisc from '../../assets/inteiros/(000027).jpg'
import surubimEvisc from '../../assets/inteiros/(000055).jpg'
import manjuba from '../../assets/inteiros/(000068).jpg'
import porquinhoEvisc from '../../assets/inteiros/(000110).jpg'
import filhoteEvisc from '../../assets/inteiros/(000175).jpg'
import tambaquiEvisc from '../../assets/inteiros/(980503).jpg'
import lomboPintado from '../../assets/lombos/(000004).jpg'
import lomboDourada from '../../assets/lombos/(000042).jpg'
import lomboCacao from '../../assets/lombos/(000077).jpg'
import lomboAnchova from '../../assets/lombos/(000102).jpg'
import postasDourada from '../../assets/postas/(000007).jpg'
import postasCacao from '../../assets/postas/(000019).jpg'
import postasPintado from '../../assets/postas/(000240).jpg'
import costelaTambaqui from '../../assets/postas/(980039).jpg'
import S from './'

const arrowLeft = <FontAwesomeIcon icon={faLongArrowAltLeft} />

type TCategory = {
  category:
    | 'bacalhaus'
    | 'camaroes'
    | 'filesDePeixe'
    | 'frutosDoMar'
    | 'peixesInteiro'
    | 'lombosDePeixe'
    | 'postasDePeixe'
}

type TCategories = {
  bacalhaus: {
    polaca: TProducts
    bacalhauDesf: TProducts
    mantaBacalhau: TProducts
    fileBacalhau: TProducts
    lomboBacalhau: TProducts
  }
  camaroes: {
    seteBBSCabeca: TProducts
    seteBBEspecial: TProducts
    seteBBG: TProducts
    seteBBM: TProducts
    vannameiDescascado: TProducts
    vannameiSCabeca: TProducts
    rosaInteiro11_15: TProducts
    rosaInteiro21_30: TProducts
    rosaLimpo16_20: TProducts
    rosaLimpo21_30: TProducts
    rosaLimpo31_40: TProducts
    rosaLimpo50_60: TProducts
  }
  filesDePeixe: {
    fileLinguado: TProducts
    fileMerluzao: TProducts
    filePescadaEsp: TProducts
    fileAbrotea: TProducts
    filePangaPremium: TProducts
    fileSardinhaEsp: TProducts
    fileMerluza: TProducts
    fileAbadejo: TProducts
    fileSurubim: TProducts
    fileCambucu: TProducts
    fileSalmaoB: TProducts
    fileTilapia: TProducts
    fileSalmaoPremium: TProducts
    filePescada: TProducts
    fileCongroRosa: TProducts
    filePangaGord: TProducts
  }
  frutosDoMar: {
    cabecaLula: TProducts
    carneDesfiada: TProducts
    caudaLagosta: TProducts
    kaniInak: TProducts
    kitPaella: TProducts
    lulaAneis: TProducts
    lulaTubo: TProducts
    mariscoMeiaConcha: TProducts
    mariscoLimpo: TProducts
    polvoEvisc: TProducts
    siriPuro: TProducts
    tentaculosLula: TProducts
  }
  peixesInteiro: {
    filhoteEvisc: TProducts
    manjuba: TProducts
    porquinhoEvisc: TProducts
    sardinhaEvisc: TProducts
    surubimEvisc: TProducts
    tambaquiEvisc: TProducts
  }
  lombosDePeixe: {
    lomboAnchova: TProducts
    lomboCacao: TProducts
    lomboDourada: TProducts
    lomboPintado: TProducts
  }
  postasDePeixe: {
    costelaTambaqui: TProducts
    postasCacao: TProducts
    postasDourada: TProducts
    postasPintado: TProducts
  }
}

type TProducts = {
  img: string
  alt: string
}

type TCategoryName = {
  bacalhaus: string
  camaroes: string
  filesDePeixe: string
  frutosDoMar: string
  peixesInteiro: string
  lombosDePeixe: string
  postasDePeixe: string
}

const categories = [
  {
    bacalhaus: {
      polaca: {
        img: polacaDesfiada,
        alt: 'Peixe Polaca salgada e desfiada'
      },
      bacalhauDesf: {
        img: bacalhauDesfiado,
        alt: 'Peixe Bacalhau Gadus macrocephalus salgado e desfiado'
      },
      mantaBacalhau: {
        img: mantaBacalhau,
        alt: 'Peixe Bacalhau SAITHE salgado'
      },
      fileBacalhau: {
        img: fileBacalhau,
        alt: 'Peixe Bacalhau filetado, dessalgado e com pele'
      },
      lomboBacalhau: {
        img: lomboBacalhau,
        alt: 'Peixe Bacalhau Gadus morhua, dessalgado e com pele'
      }
    },
    camaroes: {
      seteBBSCabeca: {
        img: seteBBSCabeca,
        alt: 'Camarão 7 Barbas sem cabeça e com casca'
      },
      seteBBEspecial: {
        img: seteBBEspecial,
        alt: 'Camarão 7 Barbas descascasdo especial'
      },
      seteBBG: {
        img: seteBBG,
        alt: 'Camarão 7 Barbas descascado G'
      },
      seteBBM: {
        img: seteBBM,
        alt: 'Camarão 7 Barbas descascado M'
      },
      vannameiDescascado: {
        img: vannameiDescascado,
        alt: 'Camarão Vannamei descascado de 80 a 100 peças no kg'
      },
      vannameiSCabeca: {
        img: vannameiSCabeca,
        alt: 'Camarão Vannamei com casca e sem cabeça de 80 a 100 peças no kg'
      },
      rosaInteiro11_15: {
        img: rosaInteiro11_15,
        alt: 'Camarão Rosa inteiro de 11 a 15 peças no kg'
      },
      rosaInteiro21_30: {
        img: rosaInteiro21_30,
        alt: 'Camarão Rosa inteiro de 21 a 30 peças no kg'
      },
      rosaLimpo16_20: {
        img: rosaLimpo16_20,
        alt: 'Camarão Rosa limpo e eviscerado com cauda de 16 a 20 peças no kg'
      },
      rosaLimpo21_30: {
        img: rosaLimpo21_30,
        alt: 'Camarão Rosa limpo e eviscerado com cauda de 21 a 30 peças no kg'
      },
      rosaLimpo31_40: {
        img: rosaLimpo31_40,
        alt: 'Camarão Rosa limpo e eviscerado com cauda de 31 a 40 peças no kg'
      },
      rosaLimpo50_60: {
        img: rosaLimpo50_60,
        alt: 'Camarão Rosa limpo e eviscerado com cauda de 50 a 50 peças no kg'
      }
    },
    filesDePeixe: {
      fileAbadejo: {
        img: fileAbadejo,
        alt: 'Filé de Abadejo argentino sem pele'
      },
      fileAbrotea: {
        img: fileAbrotea,
        alt: 'Filé de Abrótea (Bacalhau Nacional) sem pele'
      },
      fileCambucu: {
        img: fileCambucu,
        alt: 'Filé de Cambucu sem pele'
      },
      fileCongroRosa: {
        img: fileCongroRosa,
        alt: 'Filé de Congro Rosa sem pele'
      },
      fileLinguado: {
        img: fileLinguado,
        alt: 'Filé de Linguado nacional sem pele'
      },
      fileMerluza: {
        img: fileMerluza,
        alt: 'Filé de Merluza argentina sem pele'
      },
      fileMerluzao: {
        img: fileMerluzao,
        alt: 'Filé de Merluzão argentina sem pele'
      },
      filePangaPremium: {
        img: filePangaPremium,
        alt: 'Filé de Pangasius sem gordura sem pele'
      },
      filePangaGord: {
        img: filePangaGord,
        alt: 'Filé de Pangasius com gordura sem pele'
      },
      filePescada: {
        img: filePescada,
        alt: 'Filé de Pescada sem pele'
      },
      filePescadaEsp: {
        img: filePescadaEsp,
        alt: 'Pescada espalmada sem pele'
      },
      fileSalmaoB: {
        img: fileSalmaoB,
        alt: 'Filé de Salmão Industrial B sem pele'
      },
      fileSalmaoPremium: {
        img: fileSalmaoPremium,
        alt: 'Filé de Salmão Premium com pele'
      },
      fileSardinhaEsp: {
        img: fileSardinhaEsp,
        alt: 'Sardinha espalmada com pele'
      },
      fileSurubim: {
        img: fileSurubim,
        alt: 'Filé de Rosado/Surubim com pele'
      },
      fileTilapia: {
        img: fileTilapia,
        alt: 'Filé de Tilápia corte em "V" sem pele'
      }
    },
    frutosDoMar: {
      cabecaLula: {
        img: cabecaLula,
        alt: 'Cabeça de lula nacional'
      },
      carneDesfiada: {
        img: carneDesfiada,
        alt: 'Carne de peixe desfiada'
      },
      caudaLagosta: {
        img: caudaLagosta,
        alt: 'Cauda de Lagosta'
      },
      kaniInak: {
        img: kaniInak,
        alt: 'Bastonetes de Surubim sabor imitação de caranguejo'
      },
      kitPaella: {
        img: kitPaella,
        alt: 'Ingredientes para paella - Kit Paella'
      },

      lulaAneis: {
        img: lulaAneis,
        alt: 'Lula em anéis'
      },
      lulaTubo: {
        img: lulaTubo,
        alt: 'Lula em Tubos'
      },
      mariscoMeiaConcha: {
        img: mariscoMeiaConcha,
        alt: 'Marisco meia concha'
      },
      mariscoLimpo: {
        img: mariscoLimpo,
        alt: 'Marisco limpo'
      },
      polvoEvisc: {
        img: polvoEvisc,
        alt: 'Polvo inteiro e eviscerado'
      },
      siriPuro: {
        img: siriPuro,
        alt: 'Carne de Siri pura'
      },
      tentaculosLula: {
        img: tentaculosLula,
        alt: 'Tentáculos de lula gigante'
      }
    },
    peixesInteiro: {
      filhoteEvisc: {
        img: filhoteEvisc,
        alt: 'Peixe Filhote HG e eviscerado'
      },
      manjuba: {
        img: manjuba,
        alt: 'Peixe Manjuba inteiro'
      },
      porquinhoEvisc: {
        img: porquinhoEvisc,
        alt: 'Peixe Porquinho HG e eviscerado'
      },
      sardinhaEvisc: {
        img: sardinhaEvisc,
        alt: 'Peixe Sardinha HG e eviscerado'
      },
      surubimEvisc: {
        img: surubimEvisc,
        alt: 'Peixe Surubim HG e eviscerado'
      },
      tambaquiEvisc: {
        img: tambaquiEvisc,
        alt: 'Peixe Tambaqui inteiro e eviscerado'
      }
    },
    lombosDePeixe: {
      lomboAnchova: {
        img: lomboAnchova,
        alt: 'Lombo de Anchova Negra/Peixe Prego sem pele'
      },
      lomboCacao: {
        img: lomboCacao,
        alt: 'Lombo de Cação sem pele'
      },
      lomboDourada: {
        img: lomboDourada,
        alt: 'Lombo de Dourada sem pele'
      },
      lomboPintado: {
        img: lomboPintado,
        alt: 'Lombo de Pintado sem pele'
      }
    },
    postasDePeixe: {
      costelaTambaqui: {
        img: costelaTambaqui,
        alt: 'Costela de Tambaqui com pele'
      },
      postasCacao: {
        img: postasCacao,
        alt: 'Postas de Cação sem pele'
      },
      postasDourada: {
        img: postasDourada,
        alt: 'Postas de Dourada sem pele'
      },
      postasPintado: {
        img: postasPintado,
        alt: 'Postas de Pintado com pele'
      }
    }
  }
]

const categoryName = [
  {
    bacalhaus: 'Bacalhaus',
    camaroes: 'Camarões',
    filesDePeixe: 'Filés de Peixe',
    frutosDoMar: 'Frutos do Mar',
    peixesInteiro: 'Peixes Inteiro',
    lombosDePeixe: 'Lombos de Peixe',
    postasDePeixe: 'Postas de Peixe'
  }
]

function Categories ({ category }: TCategory) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 2500)
  }, [loading])

  return (
    <>
      {loading && (
        <S.Spinner>
          <div className='sk-circle1 sk-circle'></div>
          <div className='sk-circle2 sk-circle'></div>
          <div className='sk-circle3 sk-circle'></div>
          <div className='sk-circle4 sk-circle'></div>
          <div className='sk-circle5 sk-circle'></div>
          <div className='sk-circle6 sk-circle'></div>
          <div className='sk-circle7 sk-circle'></div>
          <div className='sk-circle8 sk-circle'></div>
          <div className='sk-circle9 sk-circle'></div>
          <div className='sk-circle10 sk-circle'></div>
          <div className='sk-circle11 sk-circle'></div>
          <div className='sk-circle12 sk-circle'></div>
        </S.Spinner>
      )}
      <S.CategoriesWrapper>
        <S.BackToMenuProducts>
          <Link to='/produtos'>{arrowLeft}</Link>
        </S.BackToMenuProducts>
        <S.CategoryName>
          {categoryName.map((catName: TCategoryName, id) => (
            <h2 key={id}>{Object.values(catName[category])}</h2>
          ))}
        </S.CategoryName>
        {categories.map((cat: TCategories) =>
          Object.values(cat[category]).map((fish: TProducts, id) => (
            <img key={id} src={fish.img} alt={fish.alt} loading='lazy' />
          ))
        )}
      </S.CategoriesWrapper>
    </>
  )
}

export default Categories
