import styled from 'styled-components'
import fachada from '../../assets/fachada.jpg'

const Wrapper = styled.section`
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: 'Dancing Script', cursive;
    letter-spacing: 0.1rem;
    font-size: 2.5rem;
    font-weight: 700;
    color: #152458;
    border-bottom: 0.25rem solid #152458;
    border-radius: 0.25rem;
    display: inline-block;
    margin-bottom: 3rem;
  }
`

const WaveDiv = styled.img`
  width: 100%;
`

const HistoryWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: rgb(10, 6, 89);
  background: linear-gradient(
    360deg,
    rgba(10, 6, 89, 1) 0%,
    rgba(9, 9, 121, 1) 9%,
    rgba(2, 175, 255, 1) 100%
  );

  @media (max-width: 815px) {
    flex-direction: column;
  }
`
const Fachada = styled.div`
  height: 625px;
  width: 55%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1rem;

  @media (max-width: 1100px) {
    height: 525px;
  }

  @media (max-width: 1000px) {
    height: 475px;
  }

  @media (max-width: 815px) {
    width: 100%;
    padding: 3rem;
  }

  @media (max-width: 600px) {
    height: 450px;
    width: 100%;
    padding: 1.5rem;
  }

  div {
    background-image: url(${fachada});
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 0.75rem;
    box-shadow: 0px 0px 5px 5px midnightblue;
  }
`

const Description = styled.div`
  padding: 2rem 1rem;
  width: 45%;
  display: flex;
  align-items: center;

  @media (max-width: 815px) {
    width: 100%;
    padding: 1.5rem 3rem;
  }

  p {
    font-size: 2.5rem;
    line-height: 50px;
    color: #fff;
    font-family: 'Abel', sans-serif;
    text-shadow: 1px 1px 4px rgba(100, 100, 100, 0.65);
    text-align: justify;

    @media (max-width: 1115px) {
      font-size: 2.25rem;
    }

    @media (max-width: 1000px) {
      font-size: 2rem;
      line-height: 40px;
      text-align: initial;
    }

    @media (max-width: 600px) {
      line-height: 40px;
    }

    strong {
      color: #5ef798;
    }
  }
`

const FourPrinciples = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const FourPrinciplesWrapper = styled.div`
  margin: 5rem 0;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
`

const Principle = styled.div`
  width: 48%;
  margin: 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (max-width: 815px) {
    width: 100%;
  }

  h2 {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    letter-spacing: 0.1rem;
    font-size: 2rem;
    font-weight: 700;
    color: #152458;
  }

  div {
    background-color: #065fdf;
    padding: 2rem;
    border-radius: 0.75rem;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 1000px) {
      padding: 1rem;
    }

    span {
      text-align: center;
      font-size: 5rem;
      color: #fff;
      display: block;
      height: 7rem;
    }

    p {
      font-size: 2rem;
      font-family: 'Abel', sans-serif;
      color: #fff;
      text-align: justify;
      border-left: 0.35rem solid white;
      padding-left: 0.5rem;

      @media (max-width: 1000px) {
        text-align: initial;
      }

      strong {
        color: #fbf044;
      }
    }
  }
`

export default {
  Wrapper,
  WaveDiv,
  HistoryWrapper,
  Description,
  Fachada,
  FourPrinciples,
  FourPrinciplesWrapper,
  Principle
}
