import styled from 'styled-components'

const toUp = styled.div`
  display: none;
  height: 3rem;
  width: 3rem;
  background-color: #152458;
  bottom: 1rem;
  right: 1rem;
  position: fixed;
  border-radius: 50%;
  text-align: center;
  line-height: 2.75rem;
  font-size: 3rem;
  color: white;

  &:hover {
    color: #eee;
  }

  &.active {
    display: block;
    z-index: 10;
    cursor: pointer;
  }
`

const HeaderWrapper = styled.header`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 7.5rem;
  position: relative;

  &.mob {
    margin-bottom: 25rem;
  }

  @media (min-width: 1920px) {
    margin-bottom: 12rem;
  }
`
const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex: 1;

  @media (max-width: 815px) {
    flex-direction: column;
  }
`

const WaveWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -2;
  max-width: 2560px;
`

const Wave = styled.img`
  width: 100%;
`

const LogoWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  #hamburger {
    width: 50px;
    height: 50px;
    margin-right: 0.5rem;
    display: none;

    cursor: pointer;

    @media (max-width: 815px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    span {
      width: 45px;
      height: 4px;
      background-color: white;
      position: absolute;
      border-radius: 0.25rem;

      &:nth-child(1) {
        transform: translateY(-15px);
        transition: transform 0.3s;
      }
      &:nth-child(2) {
        transform: translateY(0px);
        transition: background 0.1s;
      }

      &:nth-child(3) {
        transform: translateY(15px);
        transition: transform 0.3s;
      }
    }

    &.active span {
      &:nth-child(1) {
        transform: translateY(0px) rotate(-45deg);
      }

      &:nth-child(2) {
        background: transparent;
      }

      &:nth-child(3) {
        transform: translateY(0px) rotate(45deg);
      }
    }
  }
`

const Logo = styled.img`
  padding: 0.5rem;
  height: 10.5rem;
  background-repeat: no-repeat;
  display: flex;

  @media (max-width: 950px) {
    height: 10rem;
  }

  @media (min-width: 2480px) {
    height: 12rem;
  }
`

const NavigationDesk = styled.nav`
  height: 5rem;
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 815px) {
    display: none;
  }

  ul {
    list-style-type: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    li {
      font-family: 'Dancing Script', cursive;
      font-weight: 600;
      font-size: 2rem;

      a {
        text-decoration: none;
        padding: 0.75rem;
        border-bottom: 0.2rem solid transparent;
        transition: all 0.3s;
        color: #fff;

        &:hover {
          color: #ccc;
        }

        &.active {
          border-bottom: 0.2rem solid white;
        }
      }
    }
  }
`

const NavigationMobile = styled.nav`
  height: 5rem;
  align-self: center;
  justify-content: space-evenly;
  align-items: center;
  display: none;

  @media (max-width: 815px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .nav_mobile {
    list-style-type: none;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 6rem;
    opacity: 0;
    transition: transform 0.3s;
    pointer-events: none;

    &.active {
      opacity: 1;
      position: absolute;
      transform: translateY(0);
      pointer-events: visible;
      margin-top: 6rem;
    }

    li {
      font-family: 'Dancing Script', cursive;
      font-weight: 600;
      font-size: 2rem;
      width: 100%;
      text-align: center;
      transition: all 0.2s;
      padding: 0.25rem;

      &:hover {
        background-color: #152458;
      }

      a {
        text-decoration: none;
        padding: 0.75rem;
        border-bottom: 0.2rem solid transparent;
        transition: all 0.3s;
        color: #fff;

        &:hover {
          color: #ccc;
        }
      }
    }
  }
`

export default {
  toUp,
  HeaderWrapper,
  WaveWrapper,
  Wave,
  Container,
  LogoWrapper,
  Logo,
  NavigationDesk,
  NavigationMobile
}
