import {
  faAward,
  faCity,
  faHandshake,
  faShippingFast
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import waveDiv from '../../assets/wave-div.svg'
import S from './Index'

const estructure = <FontAwesomeIcon icon={faCity} />
const fleet = <FontAwesomeIcon icon={faShippingFast} />
const quality = <FontAwesomeIcon icon={faAward} />
const partner = <FontAwesomeIcon icon={faHandshake} />

function WhoWeAre () {
  return (
    <S.Wrapper id='whoweare'>
      <S.WaveDiv src={waveDiv} />
      <h1>Quem Somos</h1>

      <S.HistoryWrapper>
        <S.Fachada>
          <div />
        </S.Fachada>
        <S.Description>
          <p>
            A <strong>GRC Alimentos</strong> é uma empresa sólida e bem
            sucedida, localizada em Valinhos-SP, atuando principalmente no ramo
            de <strong>pescados e frutos do mar</strong>, contando com
            profissionais com experiência de mais de 25 anos na área, atendendo
            bares, restaurantes, churrascarias, pesqueiros, hóteis, entre
            outros.
          </p>
        </S.Description>
      </S.HistoryWrapper>

      <S.FourPrinciples>
        <S.FourPrinciplesWrapper>
          <S.Principle>
            <h2>Estrutura</h2>

            <div>
              <span>{estructure}</span>
              <p>
                Com sede de 700m² de área construída, capacidade de estocagem de
                60 toneladas, e áreas projetadas específicas para a distribuição
                de pescados e frutos do mar, fazem da{' '}
                <strong>GRC Alimentos</strong> uma empresa séria e competente em
                sua área de atuação.
              </p>
            </div>
          </S.Principle>

          <S.Principle>
            <h2>Frota</h2>
            <div>
              <span>{fleet}</span>
              <p>
                Frota própria, colaboradores com paixão no que fazem,
                mercadorias sempre em estoque e com alto giro de reposição.
              </p>
              <p>
                São ingredientes que nos permitem atender toda região
                metropolitana de Campinas, Sorocaba e São Paulo com eficiência e
                agilidade.
              </p>
            </div>
          </S.Principle>

          <S.Principle>
            <h2>Nossa Qualidade</h2>
            <div>
              <span>{quality}</span>
              <p>Nosso conceito de qualidade é abrangente e rigoroso.</p>
              <p>
                Tem início na escolha de parceiros leais e idôneos, no manuseio
                adequado dos produtos, técnicas de armazenagem e eficiência em
                seu sistema de logística e distribuição, no treinamento dos
                profissionais de vendas e na escolha dos melhores sistemas de
                atendimento ao cliente.
              </p>
            </div>
          </S.Principle>

          <S.Principle>
            <h2>Parceiros Comerciais</h2>
            <div>
              <span>{partner}</span>
              <p>
                A distribuição de pescados e frutos do mar requer um aprimorado
                conhecimento dos produtos, do mercado e dos fornecedores.
              </p>
              <p>
                A escolha dos parceiros comerciais é um dos pontos fortes da{' '}
                <strong>GRC Alimentos</strong>, e com base nisso, trabalha com
                segurança, oferencendo os melhores produtos aos seus clientes.
              </p>
            </div>
          </S.Principle>
        </S.FourPrinciplesWrapper>
      </S.FourPrinciples>
    </S.Wrapper>
  )
}

export default WhoWeAre
