import React from 'react'
import { Link } from 'react-router-dom'
import S from './index'

function Products () {
  return (
    <S.container>
      <header>
        <h1>Linha de Produtos</h1>
      </header>

      <S.imageItem>
        <Link to='/produtos/bacalhau'>
          <h2>Bacalhau</h2>
        </Link>
      </S.imageItem>

      <S.imageItem>
        <Link to='/produtos/camaroes'>
          <h2>Camarões</h2>
        </Link>
      </S.imageItem>

      <S.imageItem>
        <Link to='/produtos/files-de-peixe'>
          <h2>Filés de Peixe</h2>
        </Link>
      </S.imageItem>

      <S.imageItem>
        <Link to='/produtos/frutos-do-mar'>
          <h2>Frutos do Mar</h2>
        </Link>
      </S.imageItem>

      <S.imageItem>
        <Link to='/produtos/peixes-inteiro'>
          <h2>Peixes Inteiros</h2>
        </Link>
      </S.imageItem>

      <S.imageItem>
        <Link to='/produtos/lombos-de-peixe'>
          <h2>Lombos de Peixe</h2>
        </Link>
      </S.imageItem>

      <S.imageItem>
        <Link to='/produtos/postas-de-peixe'>
          <h2>Postas de Peixe</h2>
        </Link>
      </S.imageItem>
    </S.container>
  )
}

export default Products
