import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import logoGRC from '../../assets/logo-grc.svg'
import waveNormal from '../../assets/wave-grc-1.svg'
import waveSoft from '../../assets/wave-grc-2.svg'
import waveMedium from '../../assets/wave-grc-3.svg'
import waveLarge from '../../assets/wave-grc-4.svg'
import waveExtraLarge from '../../assets/wave-grc-5.svg'
import waveMobMedium from '../../assets/wave-grc-mob-2.svg'
import waveMobLarge from '../../assets/wave-grc-mob-3.svg'
import waveMobNormal from '../../assets/wave-grc-mob.svg'
import waveMobExtraLarge from '../../assets/wave-grc-mobile.svg'
import S from './index'

const arrowUp = <FontAwesomeIcon icon={faArrowAltCircleUp} />

function Header () {
  const [boundWidth, setBoundWidth] = useState(0)
  const [linkActive, setLinkActive] = useState('')
  const header = document.getElementById('header')

  let history = useHistory()

  useEffect(() => {
    const nav_mobile = document.querySelector('.nav_mobile')
    const hamburger = document.querySelector('#hamburger')
    let waveEl = document.getElementById('wave') as HTMLImageElement
    const waveWrapper = document.getElementById('wave-wrapper')

    setBoundWidth(waveWrapper!.clientWidth)

    window.addEventListener('resize', () => {
      waveEl = document.getElementById('wave') as HTMLImageElement
      const waveBounds = waveEl?.getBoundingClientRect()?.width
      setBoundWidth(waveBounds)

      nav_mobile?.classList.remove('active')
      hamburger?.classList.remove('active')
      header?.classList.remove('mob')
    })

    if (boundWidth < 1330) {
      waveEl?.setAttribute('src', waveSoft)
    }
    if (boundWidth < 875) {
      waveEl?.setAttribute('src', waveMedium)
    }
    if (boundWidth < 700) {
      waveEl?.setAttribute('src', waveLarge)
    }
    if (boundWidth < 600) {
      waveEl?.setAttribute('src', waveExtraLarge)
    }
    if (boundWidth > 1330) {
      waveEl.setAttribute('src', waveNormal)
    }
  }, [boundWidth, header])

  useEffect(() => {
    window.addEventListener('load', () => {
      setLinkActive(history.location.pathname)
    })
  }, [history])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const toUp = document.querySelector('.toUp')
      if (window.scrollY > 250) {
        toUp?.classList.add('active')
      } else {
        toUp?.classList.remove('active')
      }
    })
  })

  function handleOption (e: any, linkActive: string) {
    setLinkActive(linkActive)
    let goTo = Number(
      document
        .getElementById(`${linkActive.split('/')[1]}`)
        ?.getBoundingClientRect().top
    )
    window.scroll({ top: goTo, left: 0, behavior: 'smooth' })
  }

  function handleToggleMenu () {
    const waveEl = document.getElementById('wave') as HTMLImageElement
    const nav_mobile = document.querySelector('.nav_mobile')
    const hamburger = document.querySelector('#hamburger')

    if (!hamburger?.classList.contains('active')) {
      nav_mobile?.classList.add('active')
      hamburger?.classList.add('active')
      header?.classList.add('mob')

      if (boundWidth < 799) {
        waveEl?.setAttribute('src', waveMobNormal)
      }
      if (boundWidth < 720) {
        waveEl?.setAttribute('src', waveMobMedium)
      }
      if (boundWidth < 580) {
        waveEl?.setAttribute('src', waveMobLarge)
      }
      if (boundWidth < 480) {
        waveEl?.setAttribute('src', waveMobExtraLarge)
      }
    } else {
      nav_mobile?.classList.remove('active')
      hamburger?.classList.remove('active')
      header?.classList.remove('mob')

      if (boundWidth < 799) {
        waveEl?.setAttribute('src', waveMedium)
      }
      if (boundWidth < 710) {
        waveEl?.setAttribute('src', waveLarge)
      }
      if (boundWidth < 600) {
        waveEl?.setAttribute('src', waveExtraLarge)
      }
      if (boundWidth > 799) {
        waveEl.setAttribute('src', waveNormal)
      }
    }
  }

  function goToUp () {
    window.scrollTo(0, 0)
  }

  return (
    <>
      <S.toUp className='toUp' onClick={goToUp}>
        {arrowUp}
      </S.toUp>
      <S.HeaderWrapper id='header'>
        <S.Container>
          <S.LogoWrapper>
            <S.Logo src={logoGRC} />
            <div id='hamburger' onClick={handleToggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </S.LogoWrapper>

          <S.NavigationDesk>
            <ul>
              <li>
                <Link
                  to='/home'
                  onClick={e => handleOption(e, '/home')}
                  className={linkActive === '/home' ? 'active' : ''}
                >
                  Home
                </Link>
              </li>

              <li>
                <Link
                  to='/quem-somos'
                  onClick={e => handleOption(e, '/quem-somos')}
                  className={linkActive === '/quem-somos' ? 'active' : ''}
                >
                  Quem Somos
                </Link>
              </li>

              <li>
                <Link
                  to='/produtos'
                  onClick={e => handleOption(e, '/produtos')}
                  className={linkActive === '/produtos' ? 'active' : ''}
                >
                  Produtos
                </Link>
              </li>

              <li>
                <Link
                  to='/contato'
                  onClick={e => handleOption(e, '/contato')}
                  className={linkActive === '/contato' ? 'active' : ''}
                >
                  Contato
                </Link>
              </li>
            </ul>
          </S.NavigationDesk>

          <S.NavigationMobile>
            <ul className='nav_mobile'>
              <li>
                <Link
                  to='/home'
                  onClick={e => handleOption(e, '/home')}
                  className={linkActive === '/home' ? 'active' : ''}
                >
                  Home
                </Link>
              </li>

              <li>
                <Link
                  to='/quem-somos'
                  onClick={e => handleOption(e, '/quem-somos')}
                  className={linkActive === '/quem-somos' ? 'active' : ''}
                >
                  Quem Somos
                </Link>
              </li>

              <li>
                <Link
                  to='/produtos'
                  onClick={e => handleOption(e, '/produtos')}
                  className={linkActive === '/produtos' ? 'active' : ''}
                >
                  Produtos
                </Link>
              </li>

              <li>
                <Link
                  to='/contato'
                  onClick={e => handleOption(e, '/contato')}
                  className={linkActive === '/contato' ? 'active' : ''}
                >
                  Contato
                </Link>
              </li>
            </ul>
          </S.NavigationMobile>
        </S.Container>

        <S.WaveWrapper id='wave-wrapper'>
          <S.Wave id='wave' src={waveNormal} />
        </S.WaveWrapper>
      </S.HeaderWrapper>
    </>
  )
}

export default Header
