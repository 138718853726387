import {
  faFacebook,
  faInstagram,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import waveInverted from '../../assets/wave-div.svg'
import S from './index'

const phone = <FontAwesomeIcon icon={faPhone} />
const whatsApp = <FontAwesomeIcon icon={faWhatsapp} />
const facebook = <FontAwesomeIcon icon={faFacebook} />
const instagram = <FontAwesomeIcon icon={faInstagram} />

function contact () {
  return (
    <S.Contact>
      <img src={waveInverted} alt='Onda do Mar vetorizada.' />
      <h1>Contato</h1>

      <S.ContactWrapper>
        <address>
          <p>
            Rua Alexandre Humberto Moletta, 925
            <br />
            Jardim Pinheiros - Valinhos/SP - 13274-340{' '}
          </p>
        </address>

        <S.Business>
          <h2>Atacado</h2>
          <S.BusinessWrapper>
            <address>
              <h6>Telefones: </h6>
              <p>
                <span className='icon-phone'>{phone}</span>
                <span>(19)</span> 3244-5600
              </p>
              <p>
                <span className='icon-phone'>{phone}</span>
                <span>(19)</span> 3244-5700
              </p>
              <p>
                <span className='icon-phone'>{phone}</span>
                <span>(19)</span> 3299-0805
              </p>
            </address>
          </S.BusinessWrapper>
        </S.Business>

        <S.Business>
          <h2>Varejo</h2>
          <S.BusinessWrapper>
            <address>
              <h6>WhatsApp: </h6>
              <p>
                <a
                  href='https://wa.me/5519988256337'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span className='icon-mobile'>{whatsApp}</span>
                  <span>(19)</span> 98825-6337
                </a>
              </p>
              <p>
                <a
                  href='https://wa.me/5519998250007'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span className='icon-mobile'>{whatsApp}</span>
                  <span>(19)</span> 99825-0007
                </a>
              </p>
            </address>

            <S.Social>
              <a
                href='https://www.facebook.com/reidosmaresoficial'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span>{facebook}</span>
              </a>

              <a
                href='https://www.instagram.com/reidosmares_pescados/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span>{instagram}</span>
              </a>
            </S.Social>
          </S.BusinessWrapper>
        </S.Business>
      </S.ContactWrapper>

      <iframe
        title='grc'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.7365728316627!2d-46.98773348448664!3d-22.959926745542816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8cdef30d5e25d%3A0x3c82852ebd0bdc4d!2sRei%20dos%20Mares%20-%20Pescados%20e%20Frutos%20do%20Mar!5e0!3m2!1spt-BR!2sbr!4v1600881432491!5m2!1spt-BR!2sbr'
        width='100%'
        height='450'
        frameBorder='0'
        style={{ border: 0 }}
        allowFullScreen={false}
        aria-hidden='false'
        tabIndex={0}
      ></iframe>
    </S.Contact>
  )
}

export default contact
