import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import Categories from '../components/Categories/Categories'
import Contact from '../components/Contact/contact'
import Footer from '../components/Footer/footer'
import Header from '../components/Header/header'
import Main from '../components/Main/main'
import Products from '../components/Products/products'
import WhoWeAre from '../components/WhoWeAre/whoWeAre'

export default function App () {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path='/produtos/bacalhau' exact>
          <Categories category='bacalhaus' />
        </Route>
        <Route path='/produtos/camaroes' exact>
          <Categories category='camaroes' />
        </Route>
        <Route path='/produtos/files-de-peixe' exact>
          <Categories category='filesDePeixe' />
        </Route>
        <Route path='/produtos/frutos-do-mar' exact>
          <Categories category='frutosDoMar' />
        </Route>
        <Route path='/produtos/peixes-inteiro' exact>
          <Categories category='peixesInteiro' />
        </Route>
        <Route path='/produtos/lombos-de-peixe' exact>
          <Categories category='lombosDePeixe' />
        </Route>
        <Route path='/produtos/postas-de-peixe' exact>
          <Categories category='postasDePeixe' />
        </Route>
        <Route path='/produtos' exact>
          <Products />
        </Route>
        <Route path='/quem-somos' exact>
          <WhoWeAre />
        </Route>
        <Route path='/contato' exact>
          <Contact />
        </Route>
        <Route path='/'>
          <Main />
          <WhoWeAre />
          <Contact />
        </Route>
      </Switch>
      <Footer />
    </Router>
  )
}
