import React from 'react'
import S from './index'

function footer () {
  return (
    <S.ContactWrapper>
      <div>
        <p>
          Copyright &copy; 2020 GRC Alimentos - Todos os direitos reservados.
        </p>
      </div>
    </S.ContactWrapper>
  )
}

export default footer
